import { useCallback, useState } from "react";

import type { Api } from "../api";
import { hasStatus } from "../lib/utils";

import { usePromise } from "./usePromise";

interface UseLoginLoginOptions {
  email: string;
  password: string;
}

interface UseLoginResult {
  error?: "connection" | "credentials" | "unexpected";
  loading: boolean | undefined;
  token?: string;
  login(options: UseLoginLoginOptions): void;
  resetLogin(): void;
}

export function useLoginApi(api: Api): UseLoginResult {
  const [loginPromise, setLoginPromise] = useState<
    Promise<{ token: string }> | undefined
  >(undefined);

  const login = useCallback(
    ({ email, password }: UseLoginLoginOptions) => {
      setLoginPromise(api.login(email, password));
    },
    [api]
  );

  const resetLogin = useCallback(() => {
    setLoginPromise(undefined);
  }, []);

  try {
    const [loginResult, loading] = usePromise(loginPromise);
    const { token } = loginResult ?? {};

    return {
      loading,
      login,
      resetLogin,
      token,
    };
  } catch (error) {
    const safeError: unknown = error;
    let realError: UseLoginResult["error"] = "unexpected";

    if (hasStatus(safeError)) {
      if (safeError.status === 401) {
        realError = "credentials";
      }
    }

    return {
      error: realError,
      loading: false,
      login,
      resetLogin,
    };
  }
}
