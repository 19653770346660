import AccountCircle from "@mui/icons-material/AccountCircle";
import Chat from "@mui/icons-material/Chat";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import Gavel from "@mui/icons-material/Gavel";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import { useTranslation } from "react-i18next";
import classes from "./Navigation.module.css";
import { NavigationButton } from "./NavigationButton";

interface NavigationProps {}

export const Navigation = (props: NavigationProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <NavigationButton
        href="/accounts"
        icon={<AccountCircle />}
        text={String(t("Navigation.accounts"))}
      />
      <NavigationButton
        href="/anticheat"
        icon={<Gavel />}
        text={String(t("Navigation.anticheat"))}
      />
      <NavigationButton
        href="/chats"
        icon={<Chat />}
        text={String(t("Navigation.chats"))}
      />
      <NavigationButton
        href="/reports"
        icon={<PriorityHigh />}
        text={String(t("Navigation.reports"))}
      />
      <NavigationButton
        href="/transactions"
        icon={<CurrencyExchange />}
        text={String(t("Navigation.transactions"))}
      />
    </div>
  );
};
