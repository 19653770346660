import type { Reducer } from "react";
import { useEffect, useReducer, useRef } from "react";

import { assertNever } from "../lib/utils";

enum PromiseState {
  Pending,
  Rejected,
  Resolved,
}

type State<T> =
  | {
      state: PromiseState.Pending;
    }
  | {
      state: PromiseState.Rejected;
      value: unknown;
    }
  | {
      state: PromiseState.Resolved;
      value: T;
    };

type StateActions<T> =
  | { type: PromiseState.Pending }
  | { type: PromiseState.Rejected; error: unknown }
  | { type: PromiseState.Resolved; result: T };

function stateReducer<T>(state: State<T>, action: StateActions<T>): State<T> {
  switch (action.type) {
    case PromiseState.Pending:
      return { state: PromiseState.Pending };
    case PromiseState.Rejected:
      return {
        state: PromiseState.Rejected,
        value: action.error,
      };
    case PromiseState.Resolved:
      return {
        state: PromiseState.Resolved,
        value: action.result,
      };
    default:
      return assertNever(action);
  }
}

type UsePromiseResult<T> =
  | [T, false]
  | [undefined, true]
  | [undefined, undefined];

export function usePromise<T>(
  promise: PromiseLike<T> | undefined
): UsePromiseResult<T> {
  const activePromise = useRef<PromiseLike<T> | undefined>(undefined);
  const [state, dispatch] = useReducer<Reducer<State<T>, StateActions<T>>>(
    stateReducer,
    {
      state: PromiseState.Pending,
    }
  );

  useEffect(() => {
    const thisPromise = promise;

    activePromise.current = thisPromise;

    dispatch({
      type: PromiseState.Pending,
    });

    if (typeof thisPromise !== "undefined") {
      thisPromise.then(
        (result) => {
          if (thisPromise === activePromise.current) {
            dispatch({
              result,
              type: PromiseState.Resolved,
            });
          }
        },
        (error) => {
          if (thisPromise === activePromise.current) {
            dispatch({
              error,
              type: PromiseState.Rejected,
            });
          }
        }
      );
    }

    return () => {
      activePromise.current = undefined;
    };
  }, [promise]);

  if (typeof promise === "undefined") {
    return [undefined, undefined];
  } else {
    switch (state.state) {
      case PromiseState.Pending:
        return [undefined, true];
      case PromiseState.Rejected:
        throw state.value;
      case PromiseState.Resolved:
        return [state.value, false];
      default:
        throw assertNever(state);
    }
  }
}
