import { forwardRef } from "react";
import type { CSSProperties } from "react";
import Link from "next/link";
import type { LinkProps } from "next/link";
import { useRouter } from "next/router";

interface LegacyNavLinkProps extends LinkProps {
  activeClassName?: string;
  activeStyle?: CSSProperties;
  children: React.ReactNode;
  className?: string;
}

export const LegacyNavLink = forwardRef<HTMLAnchorElement, LegacyNavLinkProps>(
  function LegacyNavLink(
    { activeClassName, activeStyle, className, href, children, ...props },
    ref
  ) {
    const router = useRouter();
    const isActive = router.pathname === href;

    return (
      <Link
        className={[className, isActive ? activeClassName : null]
          .filter(Boolean)
          .join(" ")}
        href={href}
        ref={ref}
        {...props}
      >
        {children}
      </Link>
    );
  }
);
