import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import type { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useRef, useState } from "react";
import type { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useApi } from "../../api";
import { useInputChangeHandler } from "../../hooks/useInputChangeHandler";
import { useLoginApi } from "../../hooks/useLoginApi";
import { useStoredApiToken } from "../../store/apiToken";
import classes from "./LoginDialog.module.css";

interface LoginDialogProps extends Omit<DialogProps, "disablePortal"> {}

export const LoginDialog = (props: LoginDialogProps) => {
  const { ...restProps } = props;
  const { api } = useApi();
  const { t } = useTranslation();
  const dialogTitleId = useRef(uuid());
  const [, setApiToken] = useStoredApiToken();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handlePasswordChange = useInputChangeHandler(setPassword);
  const handleUsernameChange = useInputChangeHandler(setUsername);

  const { error, loading, login, resetLogin, token } = useLoginApi(api);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      login({
        email: username,
        password,
      });
    },
    [login, password, username]
  );

  useEffect(() => {
    if (typeof token !== "undefined") {
      setApiToken(token);

      resetLogin();
    }
  }, [resetLogin, setApiToken, token]);

  const realLoading = typeof loading !== "undefined" ? loading : false;
  const inputsDisabled = realLoading;
  const isCredentialsError = error === "credentials";

  return (
    <Dialog
      {...restProps}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      disablePortal
      aria-labelledby={dialogTitleId.current}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id={dialogTitleId.current}>
          {t("LoginDialog.title")}
        </DialogTitle>
        <DialogContent>
          <Typography color="error" variant="subtitle2">
            {typeof error !== "undefined" && t(`LoginDialog.error.${error}`)}
          </Typography>
          <TextField
            autoComplete="username"
            autoFocus
            disabled={inputsDisabled}
            error={isCredentialsError}
            fullWidth
            label={t("LoginDialog.username")}
            margin="normal"
            onChange={handleUsernameChange}
            type="email"
            value={username}
          />
          <TextField
            autoComplete="current-password"
            disabled={inputsDisabled}
            error={isCredentialsError}
            fullWidth
            label={t("LoginDialog.password")}
            margin="normal"
            onChange={handlePasswordChange}
            type="password"
            value={password}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton color="primary" loading={realLoading} type="submit">
            {t("LoginDialog.login")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
