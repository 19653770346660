import { useCallback } from "react";
import type { ChangeEvent } from "react";

export const useInputChangeHandler = (setter: (newValue: string) => void) =>
  useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(event.currentTarget.value);
    },
    [setter]
  );
